import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import styles from "./high-risk.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";

export class HighRisk extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false,
    checkedBoxes: []
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(6);
  }

  onSubmit = () => {
    this.props.goToStep(6, "/modules/normalcy/high-risk-activity");
  };

  goBack = () => {
    this.props.goToStep(5, "/modules/normalcy/rnp-unsure");
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - High Risk Activities"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <p className={styles.openingParagraph}>
            So, to recap: The normalcy law allows a foster parent to apply the
            reasonable & prudent parenting standard (ARBEFI) to decide whether a
            child in care can participate in normal childhood activities. But
            there is an important exception to this rule.
          </p>
          <div className={styles.exceptionBlurb}>
            <b>
              Exception: High risk activities{" "}
              <u>always require caseworker approval</u> in addition to foster
              parent permission.
            </b>{" "}
            High risk activities are defined as activities that have a higher
            than normal risk of physical injury.
          </div>
          <div className={styles.commonList}>
            Common activities considered high risk:
            <ul>
              <li>
                Water sports (except swimming): waterskiing, jet skiing,
                boating, rafting, etc.
              </li>
              <li>Snow sports: skiing, snowboarding, ski jumping, etc.</li>
              <li>Rock climbing</li>
              <li>Horseback riding</li>
              <li>Four-wheeling</li>
              <li>Hunting</li>
            </ul>
          </div>
          <div className={styles.finalLine}>
            <b>
              If you’re ever unsure whether an activity is considered high risk,{" "}
              ask your caseworker.
            </b>
          </div>

          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>

          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(HighRisk);
